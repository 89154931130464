@import "https://fonts.googleapis.com/css2?family=Sora:wght@400;600;700;800&display=block";
@import "https://fonts.googleapis.com/css2?family=Inter:wght@700&display=block";
html, body {
  color: #fff;
  background-color: #181818;
  height: 100vh;
  margin: 0;
  padding: 0;
}

html *, body * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Sora, sans-serif;
}

html h1, html h2, html h3, html h4, body h1, body h2, body h3, body h4 {
  text-transform: capitalize;
  text-align: center;
}

html h1, body h1 {
  font-size: 8vw;
  line-height: 125%;
}

html h2, body h2 {
  font-size: 5.5vw;
  line-height: 115%;
}

html a, body a {
  color: #fff;
}

html a.cta-button, body a.cta-button {
  color: #fff;
  background: #35d11c;
  border-radius: 9.5px;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  padding: 12px 28px;
  font-size: 5vw;
  font-weight: 600;
  text-decoration: none;
  display: inline-flex;
}

html span.text-highlight, body span.text-highlight {
  color: #ff8f1f;
  font-weight: 600;
}

html div.card, body div.card {
  background-color: #252525;
  border-radius: 6px;
}

html .flex-apart, body .flex-apart {
  justify-content: space-between;
  display: flex;
}

html section, body section {
  max-width: 100%;
}

html .section-title-container, body .section-title-container {
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  margin: 35px 0;
  display: flex;
}

html .section-title-container .subheader, body .section-title-container .subheader {
  text-transform: uppercase;
  text-align: center;
  letter-spacing: .225ex;
  font-family: Inter;
  font-size: 4vw;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

main#wrapper {
  flex-direction: column;
  align-items: center;
  gap: 80px;
  max-width: 1000px;
  margin: 20px;
  display: flex;
  position: relative;
}

header {
  justify-content: center;
  display: flex;
}

header .logo {
  height: 50px;
}

section.hero-atf {
  flex-direction: column;
  align-items: center;
  gap: 25px;
  margin-top: -60px;
  display: flex;
}

section.hero-atf h1 {
  background: radial-gradient(50% 50%, #e94057 0%, #ff8f1f 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

section.hero-atf .video-container {
  cursor: pointer;
  border: 3px solid #ff8f1f;
  border-radius: 10px;
  align-items: center;
  width: 100%;
  max-width: 700px;
  display: flex;
  overflow: hidden;
}

section.hero-atf .video-container > div {
  width: 100%;
}

section.testimonials .card-container {
  flex-direction: column;
  gap: 25vw;
  margin-top: 15vw;
  display: flex;
}

section.testimonials .card-container div.card {
  text-align: center;
  border: 1px solid #ff8f1f;
  border-radius: 16px;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 12px;
  padding: 16vw 5vw 10vw;
  font-size: 5vw;
  line-height: 155%;
  display: flex;
  position: relative;
}

section.testimonials .card-container div.card .text-container {
  flex-direction: column;
  gap: 5vw;
  display: flex;
}

section.testimonials .card-container div.card .profile-picture {
  height: 25vw;
  position: absolute;
  top: -14vw;
}

section.testimonials .card-container div.card .five-stars {
  width: 40vw;
}

section.case-studies {
  width: 100%;
  position: relative;
}

section.case-studies .swiper {
  -webkit-user-select: none;
  user-select: none;
  color: #ff8f1f;
  --swiper-navigation-size: 44px;
  --swiper-navigation-top-offset: 50%;
  --swiper-navigation-sides-offset: 10px;
  --swiper-theme-color: $ orange;
  --swiper-navigation-color: var(--swiper-theme-color);
}

section.case-studies .swiper .swiper-wrapper .swiper-slide {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100% !important;
}

section.case-studies .swiper .swiper-wrapper .swiper-slide img {
  max-width: 90%;
}

section.struggling .card-container {
  flex-direction: column;
  gap: 22vw;
  margin-top: 22vw;
  display: flex;
}

section.struggling .card-container div.card {
  text-align: center;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  padding: 16vw 5vw 4vw;
  font-size: 5vw;
  line-height: 155%;
  display: flex;
  position: relative;
}

section.struggling .card-container div.card .icon {
  height: 25vw;
  position: absolute;
  top: -18vw;
}

section.calculator, section.calculator .calculator-container {
  flex-direction: column;
  gap: 25px;
  display: flex;
}

section.calculator .calculator-container .card {
  border: .6px solid #ff8f1f;
  border-radius: 5px;
  padding: 21px;
}

section.calculator .calculator-container .card form {
  flex-direction: column;
  gap: 30px;
  display: flex;
}

section.calculator .calculator-container .card h3 {
  font-size: 5vw;
}

section.calculator .calculator-container .card .guarantee .bigger-text {
  font-size: 7vw;
  line-height: 9vw;
}

section.calculator .range-container {
  letter-spacing: -.2px;
  font-size: 4vw;
}

section.calculator .range-container input[type="range"] {
  appearance: none;
  background: none;
  width: 100%;
  height: 22px;
  margin: 10px 0;
}

section.calculator .range-container input[type="range"]:focus {
  outline: none;
}

:is(section.calculator .range-container input[type="range"]:focus::-ms-fill-lower, section.calculator .range-container input[type="range"]:focus::-ms-fill-upper, section.calculator .range-container input[type="range"]:focus::-webkit-slider-runnable-track) {
  background: #ff8f1f;
}

section.calculator .range-container input[type="range"]::-webkit-slider-runnable-track {
  cursor: pointer;
  animate: .2s;
  background: #ff8f1f;
  border: none;
  border-radius: 5px;
  width: 100%;
  height: 4px;
}

section.calculator .range-container input[type="range"]::-webkit-slider-thumb {
  box-shadow: none;
  cursor: pointer;
  appearance: none;
  background: #ff8f1f;
  border: none;
  border-radius: 50px;
  width: 16px;
  height: 16px;
  margin-top: -6px;
}

section.calculator .range-container input[type="range"]::-moz-range-track {
  cursor: pointer;
  animate: .2s;
  background: #ff8f1f;
  border: none;
  border-radius: 5px;
  width: 100%;
  height: 4px;
}

section.calculator .range-container input[type="range"]::-moz-range-thumb {
  box-shadow: none;
  cursor: pointer;
  appearance: none;
  background: #ff8f1f;
  border: none;
  border-radius: 50px;
  width: 16px;
  height: 16px;
}

section.calculator .range-container input[type="range"]::-ms-track {
  cursor: pointer;
  color: #0000;
  background: none;
  border-color: #0000;
  width: 100%;
  height: 4px;
}

:is(section.calculator .range-container input[type="range"]::-ms-fill-lower, section.calculator .range-container input[type="range"]::-ms-fill-upper, section.calculator .range-container input[type="range"]::-ms-thumb) {
  box-shadow: none;
  cursor: pointer;
  appearance: none;
  background: #ff8f1f;
  border: none;
  border-radius: 50px;
  width: 16px;
  height: 16px;
  margin-top: 1px;
}

section.calculator .range-container .top-bar {
  font-weight: 700;
}

section.calculator .range-container .bottom-bar {
  color: #ffffff80;
  font-weight: 400;
}

section.calculator .result-container {
  flex-direction: column;
  gap: 30px;
  margin-top: 35px;
  font-size: 3.5vw;
  display: flex;
}

section.calculator .result-container .flex-apart {
  gap: 4vw;
}

section.process {
  counter-reset: card;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  display: flex;
}

section.process .card {
  border: .8px solid #ff8f1f;
  border-radius: 16px;
  flex-direction: column;
  align-items: center;
  gap: 22px;
  width: 100%;
  padding: 26px 16px;
  display: flex;
  position: relative;
}

section.process .card:before {
  counter-increment: card;
  content: counter(card);
  text-shadow: .25vw .25vw #ff8f1f;
  font-size: 5vw;
  font-weight: 800;
  position: absolute;
  top: 15px;
  left: 15px;
}

section.process .card .text-container {
  flex-direction: column;
  align-items: center;
  gap: 22px;
  font-size: 4vw;
  display: flex;
}

section.process .card .text-container p, section.process .card .text-container a {
  width: 100%;
}

section.process .card .text-container h2 {
  color: #ff8f1f;
}

section.process .card img {
  width: 100%;
}

section.urgency-cta .bigger-text {
  font-size: 9vw;
  line-height: 11vw;
}

section.urgency-cta a.cta-button {
  width: 100%;
  margin-top: 20px;
  font-size: 5vw;
}

footer {
  color: #7e7e7e;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding-bottom: 10px;
  font-size: 10px;
  display: flex;
}

footer img.logo {
  width: 100px;
}

@media only screen and (width >= 750px) {
  html h1, body h1 {
    font-size: 3.5rem;
  }

  html h2, body h2 {
    font-size: 2.5rem;
  }

  html a.cta-button, body a.cta-button {
    font-size: 1.5rem;
  }

  html .section-title-container, body .section-title-container {
    margin: 35px 0;
  }

  html .section-title-container .subheader, body .section-title-container .subheader {
    font-size: 1.125rem;
  }

  main#wrapper {
    gap: 7rem;
    margin: 20px auto 12rem;
  }

  section.hero-atf h3 {
    max-width: 50%;
  }

  section.testimonials .card-container {
    flex-direction: row;
    gap: 2rem;
    margin-top: 6rem;
  }

  section.testimonials .card-container div.card {
    border: 1px solid #ff8f1f;
    border-radius: 16px;
    width: 315px;
    height: 470px;
    margin-top: 25px;
    padding: 3.5rem 1rem 1rem;
    font-size: 1.1rem;
  }

  section.testimonials .card-container div.card .profile-picture {
    height: 100px;
    top: -55px;
  }

  section.testimonials .card-container div.card .text-container {
    gap: 1.2rem;
  }

  section.testimonials .card-container div.card .five-stars {
    width: 150px;
  }

  section.struggling .card-container {
    flex-direction: row;
    gap: 2rem;
    margin-top: 6rem;
  }

  section.struggling .card-container div.card {
    margin-top: 25px;
    padding: 3rem 1rem 1rem;
    font-size: 1.2rem;
  }

  section.struggling .card-container div.card .icon {
    height: 6.25rem;
    top: -4.5rem;
  }

  section.calculator {
    width: 100%;
  }

  section.calculator .calculator-container {
    flex-direction: row;
  }

  section.calculator .calculator-container .card {
    width: 100%;
  }

  section.calculator .calculator-container .card form {
    gap: 30px;
  }

  section.calculator .calculator-container .card:has(.guarantee) {
    max-width: 380px;
    margin: 35px 0 35px 35px;
  }

  section.calculator .calculator-container .card .guarantee {
    font-size: 1.2rem;
  }

  section.calculator .calculator-container .card .guarantee .bigger-text {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  section.calculator .calculator-container .range-container, section.calculator .calculator-container .result-container {
    font-size: 1rem;
  }

  section.process {
    gap: 24px;
  }

  section.process .card {
    border: .8px solid #ff8f1f;
    border-radius: 20px;
    flex-direction: row;
    margin-top: 30px;
    padding: 1.8rem;
  }

  section.process .card:nth-child(2n) {
    flex-direction: row-reverse;
  }

  section.process .card:before {
    text-shadow: 1px 1px #ff8f1f;
    font-size: 1.1rem;
  }

  section.process .card .text-container {
    align-items: flex-start;
    max-width: 50%;
    padding: 1.5rem;
    font-size: 1rem;
  }

  section.urgency-cta {
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  section.urgency-cta .bigger-text {
    font-size: 3.7rem;
    line-height: 135%;
  }

  section.urgency-cta a.cta-button {
    width: auto;
    margin-top: 20px;
    font-size: 1.5rem;
  }
}

/*# sourceMappingURL=index.edb42806.css.map */
