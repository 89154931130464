@import url("https://fonts.googleapis.com/css2?family=Sora:wght@400;600;700;800&display=block");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@700&display=block");

$background-color: #181818;
$light-background-color: #252525;
$orange: #ff8f1f;
$white: #fff;
$green: #35d11c;

$text-color: $white;
$text-highlight-color: $orange;
$cta-button-color: $green;

html,
body {
    padding: 0;
    margin: 0;
    background-color: $background-color;
    color: $text-color;
    height: 100vh;

    * {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        font-family: "Sora", sans-serif;
    }

    h1,
    h2,
    h3,
    h4 {
        text-transform: capitalize;
        text-align: center;
    }

    h1 {
        font-size: 8vw;
        line-height: 125%;
    }
    h2 {
        font-size: 5.5vw;
        line-height: 115%;
    }
    a {
        color: $text-color;
    }

    a.cta-button {
        display: inline-flex;
        padding: 12px 28px;
        justify-content: center;
        align-items: center;
        border-radius: 9.5px;
        background: $cta-button-color;

        font-size: 5vw;
        color: $text-color;
        text-decoration: none;
        font-weight: 600;

        margin: 20px 0;
    }

    span.text-highlight {
        color: $text-highlight-color;
        font-weight: 600;
    }

    div.card {
        background-color: $light-background-color;
        border-radius: 6px;
    }

    .flex-apart {
        display: flex;
        justify-content: space-between;
    }
    section {
        max-width: 100%;
    }

    .section-title-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
        margin: 35px 0;

        .subheader {
            text-transform: uppercase;
            text-align: center;
            font-family: Inter;
            font-size: 4vw;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: 0.225ex;
        }
    }
}

main#wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 80px;
    position: relative;
    margin: 20px;
    max-width: 1000px;
}

header {
    .logo {
        height: 50px;
    }
    display: flex;
    justify-content: center;
}

section.hero-atf {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    margin-top: -60px;
    h1 {
        background: radial-gradient(50% 50% at 50% 50%, #e94057 0%, $orange 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .video-container {
        width: 100%;
        max-width: 700px;
        display: flex;
        align-items: center;
        cursor: pointer;
        border: 3px solid $orange;
        overflow: hidden;
        border-radius: 10px;

        > div {
            width: 100%;
        }
    }
}

section.testimonials {
    .card-container {
        margin-top: 15vw;
        display: flex;
        flex-direction: column;
        gap: 25vw;

        div.card {
            border-radius: 16px;
            border: 1px solid #ff8f1f;
            padding: 16vw 5vw 10vw 5vw;
            display: flex;
            align-items: center;
            justify-content: start;
            text-align: center;
            flex-direction: column;
            font-size: 5vw;
            line-height: 155%;
            gap: 12px;
            position: relative;

            .text-container {
                display: flex;
                flex-direction: column;
                gap: 5vw;
            }

            .profile-picture {
                height: 25vw;
                position: absolute;
                top: -14vw;
            }

            .five-stars {
                width: 40vw;
            }
        }
    }
}

section.case-studies {
    width: 100%;
    position: relative;
    .swiper {
        user-select: none;
        color: $orange;
        --swiper-navigation-size: 44px;
        --swiper-navigation-top-offset: 50%;
        --swiper-navigation-sides-offset: 10px;
        --swiper-theme-color: $orange;
        --swiper-navigation-color: var(--swiper-theme-color);
        .swiper-wrapper {
            .swiper-slide {
                width: 100% !important;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    max-width: 90%;
                }
            }
        }
    }
}

section.struggling {
    .card-container {
        margin-top: 22vw;
        display: flex;
        flex-direction: column;
        gap: 22vw;

        div.card {
            border-radius: 6px;
            padding: 16vw 5vw 4vw 5vw;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: 5vw;
            line-height: 155%;
            position: relative;

            .icon {
                height: 25vw;
                position: absolute;
                top: -18vw;
            }
        }
    }
}

section.calculator {
    display: flex;
    flex-direction: column;
    gap: 25px;
    .calculator-container {
        display: flex;
        flex-direction: column;
        gap: 25px;
        .card {
            padding: 21px;
            border-radius: 5px;
            border: 0.6px solid $orange;

            form {
                display: flex;
                flex-direction: column;
                gap: 30px;
            }

            h3 {
                font-size: 5vw;
            }
            .guarantee {
                .bigger-text {
                    font-size: 7vw;
                    line-height: 9vw;
                }
            }
        }
    }
    .range-container {
        font-size: 4vw;
        letter-spacing: -0.2px;

        $slider-color: $orange;
        $slider-thumb-size: 16px;
        $slider-thumb-border-radius: 50px;
        $slider-track-height: 4px;
        $slider-track-border-radius: 5px;

        @mixin slider-track {
            width: 100%;
            height: $slider-track-height;
            cursor: pointer;
            animate: 0.2s;
            background: $slider-color;
            border: none;
            border-radius: $slider-track-border-radius;
        }

        @mixin slider-thumb {
            box-shadow: none;
            border: none;
            height: $slider-thumb-size;
            width: $slider-thumb-size;
            border-radius: $slider-thumb-border-radius;
            background: $slider-color;
            cursor: pointer;
            -webkit-appearance: none;
            appearance: none;
        }

        input[type="range"] {
            height: 22px;
            -webkit-appearance: none;
            appearance: none;
            margin: 10px 0;
            width: 100%;
            background: none;

            &:focus {
                outline: none;

                &::-ms-fill-lower,
                &::-ms-fill-upper,
                &::-webkit-slider-runnable-track {
                    background: $slider-color;
                }
            }

            &::-webkit-slider-runnable-track {
                @include slider-track;
            }

            &::-webkit-slider-thumb {
                @include slider-thumb;
                margin-top: -6px;
            }

            &::-moz-range-track {
                @include slider-track;
            }

            &::-moz-range-thumb {
                @include slider-thumb;
            }

            &::-ms-track {
                width: 100%;
                height: $slider-track-height;
                cursor: pointer;
                background: transparent;
                border-color: transparent;
                color: transparent;
            }

            &::-ms-fill-lower,
            &::-ms-fill-upper,
            &::-ms-thumb {
                @include slider-thumb;
                margin-top: 1px;
            }
        }

        .top-bar {
            font-weight: 700;
        }

        .bottom-bar {
            font-weight: 400;
            color: rgba(255, 255, 255, 0.5);
        }
    }

    .result-container {
        display: flex;
        flex-direction: column;
        gap: 30px;
        font-size: 3.5vw;
        margin-top: 35px;
        .flex-apart {
            gap: 4vw;
        }
    }
}

section.process {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    counter-reset: card;

    .card {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 22px;
        position: relative;

        width: 100%;
        padding: 26px 16px;
        border: 0.8px solid $orange;
        border-radius: 16px;

        &::before {
            counter-increment: card;
            content: counter(card);
            position: absolute;
            left: 15px;
            top: 15px;

            font-weight: 800;
            font-size: 5vw;
            text-shadow: 0.25vw 0.25vw 0px 0px $orange;
        }

        .text-container {
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 22px;
            font-size: 4vw;

            p,
            a {
                width: 100%;
            }

            h2 {
                color: $text-highlight-color;
            }
        }

        img {
            width: 100%;
        }
    }
}

section.urgency-cta {
    .bigger-text {
        font-size: 9vw;
        line-height: 11vw;
    }
    a.cta-button {
        width: 100%;
        font-size: 5vw;
        margin-top: 20px;
    }
}

footer {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: column;
    font-size: 10px;
    color: rgb(126, 126, 126);
    padding-bottom: 10px;

    img.logo {
        width: 100px;
    }
}

@media only screen and (min-width: 750px) {
    html,
    body {
        h1 {
            font-size: 3.5rem;
        }
        h2 {
            font-size: 2.5rem;
        }

        a.cta-button {
            font-size: 1.5rem;
        }

        .section-title-container {
            margin: 35px 0;

            .subheader {
                font-size: 1.125rem;
            }
        }
    }

    main#wrapper {
        gap: 7rem;
        margin: 20px auto 12rem auto;
    }

    section.hero-atf {
        h3 {
            max-width: 50%;
        }
    }

    section.testimonials {
        .card-container {
            margin-top: 6rem;
            gap: 2rem;
            flex-direction: row;
            div.card {
                margin-top: 25px;
                padding: 3.5rem 1rem 1rem 1rem;
                font-size: 1.1rem;
                height: 470px;
                width: 315px;
                border-radius: 16px;
                border: 1px solid #ff8f1f;

                .profile-picture {
                    height: 100px;
                    top: -55px;
                }

                .text-container {
                    gap: 1.2rem;
                }

                .five-stars {
                    width: 150px;
                }
            }
        }
    }

    section.struggling {
        .card-container {
            margin-top: 6rem;
            gap: 2rem;
            flex-direction: row;
            div.card {
                margin-top: 25px;
                padding: 3rem 1rem 1rem 1rem;
                font-size: 1.2rem;

                .icon {
                    height: 6.25rem;
                    top: -4.5rem;
                }
            }
        }
    }

    section.calculator {
        width: 100%;

        .calculator-container {
            flex-direction: row;

            .card {
                width: 100%;

                form {
                    gap: 30px;
                }

                &:has(.guarantee) {
                    margin: 35px 0px 35px 35px;
                    max-width: 380px;
                }

                .guarantee {
                    font-size: 1.2rem;
                    .bigger-text {
                        font-size: 1.5rem;
                        line-height: 2rem;
                    }
                }
            }

            .range-container,
            .result-container {
                font-size: 1rem;
            }
        }
    }

    section.process {
        gap: 24px;

        .card {
            flex-direction: row;
            margin-top: 30px;
            padding: 1.8rem;
            border: 0.8px solid $orange;
            border-radius: 20px;

            &:nth-child(even) {
                flex-direction: row-reverse;
            }

            &::before {
                font-size: 1.1rem;
                text-shadow: 1px 1px 0px 0px $orange;
            }

            .text-container {
                align-items: flex-start;
                font-size: 1rem;
                padding: 1.5rem;
                max-width: 50%;
            }
        }
    }

    section.urgency-cta {
        display: flex;
        flex-direction: column;
        align-items: center;
        .bigger-text {
            font-size: 3.7rem;
            line-height: 135%;
        }
        a.cta-button {
            width: auto;
            font-size: 1.5rem;
            margin-top: 20px;
        }
    }
}
